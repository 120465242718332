<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4">
                    <template v-slot:start>
                        <div class="my-2">
                            <Button label="Filtros" icon="pi pi-plus" class="p-button-success mr-2"
                                @click="abrirFiltros" />

                        </div>
                    </template>

                    <template v-slot:end>
                        <!-- <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
                        <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)" />
                    </template>
                </Toolbar>

                <!-- Tabela de modos de falha -->
                <DataTable ref="dt" :value="errosCockpit" v-model:expandedRows="selectImplantacoes" dataKey="id"
                    :paginator="true" :rows="20" :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 25]"
                    currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                    responsiveLayout="scroll">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Cockpit - Implantação</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <!-- <i class="pi pi-search" /> -->
                                <InputText v-model="filters['global'].value" placeholder="Buscar" />
                            </span>
                        </div>
                    </template>

                    <Column field="extracao" header="Extração" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Extração</span>
                            <span>{{ slotProps.data.fatura?.id_extracao ?? '' }}</span>
                        </template>
                    </Column>

                    <Column field="fatura" header="Fatura" :sortable="true" headerStyle="width:5%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Fatura</span>
                            <span>{{ slotProps.data.fatura?.id ?? '' }}</span>
                        </template>
                    </Column>

                    <Column field="razao_social" header="Empresa" :sortable="true"
                        headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Empresa</span>
                            <span>{{ slotProps.data.cliente.razao_social }}</span>
                        </template>
                    </Column>

                    <Column field="quantidade_arquivos" header="Modo de Falha" :sortable="true"
                        style="text-align: center;" headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Modo de Falha</span>
                            <Tag severity="primary"
                                :style="{ backgroundColor: '#ff4d4f',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                    }"
                                v-if="slotProps.data.tipo == 0" :value="slotProps.data.status_erro.descricao">
                            </Tag>

                            <Tag severity="primary" :style="{ backgroundColor: 'orange',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                    }"
                                v-if="slotProps.data.tipo == 1" value="Erro de implantação">
                            </Tag>
                        </template>
                    </Column>

                    <Column field="status" header="Status" :sortable="true" headerStyle="width:20%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Status</span>

                            <Tag severity="primary"
                                :style="{ backgroundColor: '#999',
                                    width: '100%',
                                    display: 'flex', 
                                    justifyContent: 'center' 
                                    }"
                                :value="slotProps.data.status === 0 ? 'Aguardando correção' : 'Corrigido'"
                            >
                            </Tag>

                        </template>
                    </Column>

                    <Column field="data_fila" header="Data Envio" :sortable="true"
                        headerStyle="width:30%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Data</span>
                            {{ slotProps.data.fila.data_criacao }}
                        </template>
                    </Column>
                    <Column headerStyle="min-width:20rem;">
                        <template #body="slotProps">

                            <Button 
                                icon="pi pi-pencil" 
                                label="Platypus" 
                                class="p-button p-button-success mr-2 cursor-pointer"
                                v-if="slotProps.data.tipo == 0"
                                @click="openPlatypus" 
                            />

                            <Button 
                                icon="pi pi-pencil" 
                                label="Correção" 
                                class="p-button p-button-primary mr-2 cursor-pointer" 
                                v-if="slotProps.data.tipo == 1"
                                @click="abrirModalEdicaoFatura(slotProps.data.fatura.id, slotProps.data.erros)"
                            />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible="ModalFiltros" :style="{ width: '600px' }" header="Filtros" :modal="true"
                    class="p-fluid">
                    <div class="field">
                        <label for="id_empresa">Modos de Falha</label>
                        <Dropdown id="id_empresa" filter required="true" v-model="filtros.id_empresa"
                            :options="tipos_erros" optionLabel="label" optionValue="value"
                            placeholder="Selecione um tipo"></Dropdown>
                    </div>
                    <div class="field">
                        <label for="unidade">Unidade</label>
                        <InputText id="unidade" v-model.trim="filtros.unidade" required="true" autofocus />

                    </div>
                    <div class="field">
                        <label for="mes_referencia">Mês Referencia</label>
                        <Calendar id="mes_referencia" view="month" dateFormat="mm/yy" v-model="filtros.mes_referencia"
                            required="true" autofocus />

                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.ModalFiltros = false" />
                        <Button label="Pesquisar" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="pesquisar()" />
                    </template>
                </Dialog>
                
                <Dialog 
                    v-model:visible="modalFatura" 
                    header="Correções"
                    :modal="true" 
                    class="p-fluid" 
                    style="width: 95%; height: 100%"
                >
                <div class="grid mt-4">
                <Accordion class="col-12">
                    <AccordionTab
                    v-for="(erro, index) in errosCockpitFatura"
                        :key="erro.id"
                        :header="'Erro #' + (index + 1) + ' - ' + erro.status_erro.descricao"
                    >
                        <p><strong>ID:</strong> {{ erro.id }}</p>
                        <p><strong>Descrição:</strong> {{ erro.status_erro.descricao }}</p>
                        <p><strong>Tipo de erro:</strong> {{ erro.status_erro.tipo_erro.descricao }}</p>
                    </AccordionTab>
                </Accordion>
                </div>

                    <div class="grid mt-4">
                        <div class="col-6" style="position: sticky; top: 0; height: 100vh;">
                            <iframe 
                            :src="fatura.link_fatura + '#toolbar=0&navpanes=0'"
                            width="100%" 
                            height="100%"
                            type="application/pdf"
                            ></iframe>
                        </div>
                        
                        <div class="col-6">
                            <div class="grid ml-5 mt-2 gap-4">
                                
                                <div>
                                    <h2 class="text-xl font-medium">
                                        Dados do cliente
                                    </h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <div class="col-12 grid">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="nome_empresa">Nome da empresa</label>
                                                    <InputText id="nome_empresa" v-model.trim="fatura.dados_faturas.nome_empresa" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="cnpj">CNPJ</label>
                                                    <InputText id="cnpj" v-model.trim="fatura.dados_faturas.cnpj" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="inscricao_estadual">Inscrição estadual</label>
                                                    <InputText id="inscricao_estadual" v-model.trim="fatura.dados_faturas.inscricao_estadual" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-8">
                                                <div class="field">
                                                    <label for="endereco">Endereço</label>
                                                    <InputText id="endereco" v-model.trim="fatura.dados_faturas.endereco" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="unidade_consumidora">Unidade consumidora</label>
                                                    <InputText id="unidade_consumidora" v-model.trim="fatura.dados_faturas.unidade_consumidora" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h2 class="text-xl font-medium">
                                        Dados básicos
                                    </h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <div class="grid col-12">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="concessionaria">Concessionária</label>
                                                    <InputText 
                                                        id="concessionaria" 
                                                        v-model.trim="fatura.concessionaria.sigla" 
                                                        required="true"
                                                        autofocus 
                                                        readonly />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="nivel_tensao">Nível de tensão</label>
                                                    <InputText  
                                                        id="nivel_tensao" 
                                                        v-model.trim="fatura.tipos_tensao_unidades.descricao" 
                                                        required="true"
                                                        autofocus 
                                                        readonly />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="tipo_insumo">Tipo de insumo</label>
                                                    <InputText 
                                                        id="tipo_insumo" 
                                                        v-model.trim="fatura.tipos_insumos_unidades.descricao" 
                                                        required="true"
                                                        autofocus 
                                                        readonly />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="total_fatura">Total da fatura</label>
                                                    <InputText 
                                                        id="total_fatura"
                                                        v-model="fatura.dados_faturas.total_fatura"
                                                        required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="conta_contrato">Conta contrato</label>
                                                    <InputText id="conta_contrato" v-model.trim="fatura.dados_faturas.conta_contrato" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="tipo_ligacao">Tipo de ligação</label>
                                                    <Dropdown
                                                        :options="tipos_ligacao"
                                                        v-model="fatura.id_tipo_ligacao"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um tipo de ligação"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 grid">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="mes_referencia">Mês referência</label>
                                                    <Calendar 
                                                        v-model="fatura.mes_referencia" 
                                                        id="mes_referencia" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_vencimento">Data de vencimento</label>
                                                    <Calendar 
                                                        v-model="fatura.data_vencimento" 
                                                        id="data_vencimento" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_emissao">Data de emissão</label>
                                                    <Calendar 
                                                        v-model="fatura.data_emissao_fatura" 
                                                        id="data_emissao" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="classe">Classe</label>
                                                    <Dropdown
                                                        :filter="true"
                                                        :options="tiposClassesUnidades"
                                                        v-model="fatura.id_classe"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um item normalizado para classe"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="subclasse">Subclasse</label>
                                                    <Dropdown
                                                        :options="tiposSubClassesUnidades"
                                                        v-model="fatura.id_subclasse"
                                                        :filter="true"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um item normalizado para subclasse"
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field"> 
                                                    <label for="modalidade">Modalidade</label>
                                                    <Dropdown
                                                        :options="tiposModalidadesUnidades"
                                                        v-model="fatura.id_modalidade"
                                                        :filter="true"
                                                        optionValue="id"
                                                        optionLabel="descricao"
                                                        placeholder="Selecione um item normalizado para modalidade"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-12">
                                                <div class="field">
                                                    <label for="informacoes">Informações</label>
                                                    <Textarea 
                                                        id="informacoes" 
                                                        v-model.trim="fatura.dados_faturas.informacoes" 
                                                        required="true"
                                                        rows="5" 
                                                        cols="30" 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h2 class="text-xl font-medium">
                                        Dados de leitura
                                    </h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <div class="col-12 grid">
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_leitura_anterior">Data de leitura anterior</label>
                                                    <Calendar 
                                                        v-model="fatura.dados_faturas.data_leitura_anterior" 
                                                        id="data_leitura_anterior" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_leitura_atual">Data de leitura atual</label>
                                                    <Calendar 
                                                        v-model="fatura.dados_faturas.data_leitura_atual" 
                                                        id="data_leitura_atual" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="data_proxima_leitura">Data da próxima leitura</label>
                                                    <Calendar 
                                                        v-model="fatura.dados_faturas.data_proxima_leitura" 
                                                        id="data_emissao" 
                                                        dateFormat="yy-mm-dd" 
                                                        placeholder="Selecione a data"
                                                        class="gap-1" 
                                                        showIcon 
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="grid col-12">
                                            <div class="col-3">
                                                <div class="field">
                                                    <label for="dias_leitura">Dias de leitura</label>
                                                    <InputText id="dias_leitura" v-model.trim="fatura.dados_faturas.dias_fatura" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Start Dados Fiscais Field -->
                                <div>
                                    <h2 class="text-xl font-medium">Dados Fiscais</h2>

                                    <div class="col-12 grid bg-gray-100 p-4 mt-2 border-round">
                                        <!-- Start Columns Dados Fiscais Field -->
                                        <div class="col-12 grid">
                                            
                                            <div class="col-4">
                                                <div class="field">
                                                    <label for="nota_fiscal">Nota Fiscal</label>
                                                    <InputText id="nota_fiscal" v-model.trim="fatura.dados_fiscais_faturas.nota_fiscal" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-2">
                                                <div class="field">
                                                    <label for="serie_nota_fiscal">Serie nota fiscal</label>
                                                    <InputText id="serie_nota_fiscal" v-model.trim="fatura.dados_fiscais_faturas.serie_nota_fiscal" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="field">
                                                    <label for="fisco">Fisco</label>
                                                    <InputText id="fisco" v-model.trim="fatura.dados_fiscais_faturas.fisco" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-3">
                                                <div class="field">
                                                    <label for="qr_code_chave_acesso">Chave de acesso</label>
                                                    <InputText id="qr_code_chave_acesso" v-model.trim="fatura.dados_fiscais_faturas.qr_code_chave_acesso" required="true"
                                                        autofocus />
                                                </div>
                                            </div>


                                            <div class="col-3">
                                                <div class="field">
                                                    <label for="qr_code_protocolo">Protocolo</label>
                                                    <InputText id="qr_code_protocolo" v-model.trim="fatura.dados_fiscais_faturas.qr_code_protocolo" required="true"
                                                        autofocus />
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="field">
                                                    <label for="codigo_de_barras">Código de barras</label>
                                                    <InputText id="codigo_de_barras" v-model.trim="fatura.dados_fiscais_faturas.codigo_de_barras" required="true"
                                                        autofocus />
                                                </div>
                                            </div>
                                        </div>
                                        <!-- End Columns Dados Fiscais Field -->
                                    </div>
                                </div>
                                <!-- End Dados Fiscais Field -->


                                <div class="col-12">
                                    <DataTable
                                            ref="dt"
                                            :value="fatura.dados_itens_faturados"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                        >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Itens Faturados</h5>
                                                    </div>
                                                    
                                                    <div class="col-5">
                                                        <Button 
                                                            label="Novo item faturado" 
                                                            icon="pi pi-plus" 
                                                            class="p-button-success" 
                                                            @click="abrirModalAdicaoItemFaturado" />
                                                    </div>

                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search" />
                                                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="cci" header="CCI"></Column>
                                            <Column field="descricao_normalizada.descricao" header="Descrição"></Column>
                                            <Column field="unidade_medida" header="Unidade de medida"></Column>
                                            <Column field="quantidade" header="Quantidade"></Column>
                                            <Column field="tarifa_com_tributos" header="Tarifa c/ tributos"></Column>
                                            <Column field="tarifa_sem_tributos" header="Tarifa s/ tributos"></Column>
                                            <Column field="valor" header="Valor"></Column>
                                            <Column field="aliquota_icms" header="Alíquota ICMS"></Column>
                                            <Column field="valor_icms" header="Valor ICMS"></Column>
                                            <Column field="valor_pis" header="Valor PIS"></Column>
                                            <Column field="valor_cofins" header="Valor COFINS"></Column>
                                            <Column field="valor_pis_cofins" header="Valor PIS/COFINS"></Column>
                                            <Column field="valor_base_calculo_icms" header="Valor base calc. ICMS"></Column>
                                            <Column field="valor_base_calculo_pis_cofins" header="Valor base calc. PIS/COFINS"></Column>
                                    
                                            <Column header="">
                                                <template #body="slotProps">
                                                <!-- Botões de editar e excluir -->
                                                <Button 
                                                    icon="pi pi-pencil" 
                                                    class="p-button-rounded p-button-success mr-2" 
                                                    @click="abrirModalEdicaoItemFaturado(slotProps.data)"
                                                />
                                                <Button 
                                                    icon="pi pi-trash" 
                                                    class="p-button-rounded p-button-warning mt-2" 
                                                    @click="deleteItemFaturado(slotProps.data.id)"
                                                />
                                                </template>
                                            </Column>
                                    </DataTable>
                                </div>

                                <!-- Start Media tensão block -->
                                <div v-if="fatura.id_tensao != 1" style="width: 100%;">

                                    <!-- Start Demanda contratada block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="fatura.dados_demanda_contratada_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            editMode="cell"
                                            @cell-edit-complete="salvarEdicao">
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Demandas contratadas</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Nova Demanda Contratada" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_demanda_contratada_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search" />
                                                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="descricao" header="Descricao">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="quantidade" header="Quantidade">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    

                                            <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash" 
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_demanda_contratada_faturas')"
                                                    />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                    <!-- End Demanda contratada block -->


                                    <!-- Start Niveis Tensão Faturas block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="fatura.dados_niveis_tensao_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            editMode="cell"
                                            @cell-edit-complete="salvarEdicao">
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Níveis Tensão Fatura</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Adicionar Niveis Tensão" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_niveis_tensao_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search" />
                                                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="descricao" header="Descricao">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="quantidade" header="Quantidade">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    

                                            <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash"  
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_niveis_tensao_faturas')"
                                                    />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                    <!-- End Niveis Tensão Faturas block -->


                                    <!-- Start Leituras Faturas block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="fatura.dados_leituras_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            editMode="cell"
                                            @cell-edit-complete="salvarEdicao">
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Leituras Fatura Média Tensão</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Adicionar Leitura para Média Tensão" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_leituras_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search" />
                                                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="unidade_medida" header="Unidade Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="posto_horario" header="Posto Horário">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_atual" header="Leitura Atual">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_anterior" header="Leitura Anterior">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="fator_multiplicador" header="Fator Multiplicador">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="perdas" header="Perdas">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="fator_potencia" header="Fator Potencia">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="ajuste_fator_potencia" header="Ajuste Fator Potencia">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="consumo_medido" header="Consumo medido">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="consumo_faturado" header="Consumo Faturado">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="consumo_contratado" header="Consumo Contratado">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_medida" header="Demanda Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_contratada" header="Demanda Contratada">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    
                                            <Column field="dmcr_medida" header="DMCR Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_medida" header="Ufer Medida">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="kva_medido" header="KVA Medido">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="reservado_consumo" header="Reservado consumo">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="energia_injetada_fora_ponta" header="Energia Inj. Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="energia_injetada_ponta" header="Energia Inj. Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="energia_injetada_reservado" header="Energia Inj. Reservado">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_fora_ponta" header="Demanda Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="demanda_ponta" header="Demanda Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_fora_ponta" header="Ufer Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_ponta" header="Ufer Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="ufer_reservado" header="Ufer Reservado">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="dmcr_fora_ponta" header="DMCR Fora Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="dmcr_ponta" header="DMCR Ponta">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="tarifa_com_tributos" header="Tarifas com tributos">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column field="total" header="Total">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                    

                                            <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash"  
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_leituras_faturas')"
                                                    />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                    <!-- End Leituras Faturas block -->


                                </div>
                                <!-- End Media tensão block -->
                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="fatura.dados_medidor_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            editMode="cell"
                                            @cell-edit-complete="salvarEdicao"
                                        >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Dados medidores & leituras</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Novo item medidor" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_medidor_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search" />
                                                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="medidor" header="Medidor">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="grandeza" header="Grandeza">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="posto_horario" header="Posto Horário">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="constante_medidor" header="Constante">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_anterior" header="Leitura Anterior">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="leitura_atual" header="Leitura Atual">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="consumo_medidor" header="Consumo Medidor">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            

                                            <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash" 
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_medidor_faturas')"
                                                    />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                    <!-- End Niveis Tensão Faturas block -->

                                    <div class="col-12">
                                        <DataTable
                                            ref="dt"
                                            :value="fatura.dados_tributos_faturas"
                                            dataKey="id"
                                            :paginator="true"
                                            :rows="5"
                                            :filters="filters"
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            :rowsPerPageOptions="[5, 10, 25]"
                                            currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
                                            responsiveLayout="scroll"
                                            style="table-layout: auto; white-space: nowrap;"
                                            editMode="cell"
                                            @cell-edit-complete="salvarEdicao"
                                        >
                                            <template #header>
                                                <div class="grid align-items-center">
                                                    <div class="col-12">
                                                        <h5>Dados tributos fatura</h5>
                                                    </div>
                                                    <div class="col-5">
                                                        <Button label="Novo item tributos" icon="pi pi-plus" class="p-button-success" @click="adicionarLinha('dados_tributos_faturas')" />
                                                    </div>
                                                    <div class="col-7">
                                                        <span class="p-input-icon-left">
                                                            <i class="pi pi-search" />
                                                            <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>

                                            <Column field="id" header="ID"></Column>
                                            <Column field="id_fatura" header="Id Fatura"></Column>
                                            <Column field="descricao" header="descricao">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="aliquota" header="Aliquota">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="base_calculo" header="Base Calculo">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>
                                            <Column field="valor" header="Valor Reais">
                                                <template #editor="{ data, field }">
                                                    <InputText v-model="data[field]" />
                                                </template>
                                            </Column>

                                            <Column header="">
                                                <template #body="slotProps">
                                                    <Button 
                                                        icon="pi pi-trash" 
                                                        class="p-button-rounded p-button-danger mt-2" 
                                                        @click="deletarLinha(slotProps.data, 'dados_tributos_faturas')"
                                                    />
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.modalFatura = false" />
                        <Button label="Salvar fatura" icon="pi pi-check" class="p-button-success p-button-sm" :loading="loading"
                            @click="salvarFatura" />
                    </template>
                </Dialog>

                <Dialog 
                    v-model:visible="modalItemFaturado" 
                    header="Correções"
                    :modal="true" 
                    class="p-fluid" 
                    style="width: 95%; height: 100%"
                >
                    <div class="grid">
                        <div class="col-6" style="position: sticky; top: 0; height: 100%;">
                            <iframe 
                                :src="fatura.link_fatura"
                                width="100%" height="500px"
                                type="application/pdf"
                            ></iframe>
                        </div>
                        
                        <div class="col-6">
                            <div class="grid ml-5 mt-2">
                                <div class="grid col-12">
                                    <div class="field col-2">
                                        <label for="cci-item-faturado">CCI</label>
                                        <InputText 
                                            id="cci-item-faturado" 
                                            v-model.trim="itemFaturado.cci" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-4">
                                        <label for="descricao-item-faturado">Descrição</label>
                                        <Dropdown
                                            :options="tiposItensNormalizados"
                                            v-model="itemFaturado.id_classificacao"
                                            optionValue="id"
                                            optionLabel="descricao"
                                            placeholder="Selecione um item normalizado para modalidade"
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="quantidade-item-faturado">Quantidade</label>
                                        <InputText 
                                            id="quantidade-item-faturado" 
                                            v-model.trim="itemFaturado.quantidade" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="unidade-medida-item-faturado">Unidade de medida</label>
                                        <InputText 
                                            id="unidade-medida-item-faturado" 
                                            v-model.trim="itemFaturado.unidade_medida" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>

                                <div class="grid col-12">
                                    <div class="field col-3">
                                        <label for="tarifa-com-tributos-item-faturado">Tarifa c/ tributos</label>
                                        <InputText 
                                            id="tarifa-com-tributos-item-faturado" 
                                            v-model.trim="itemFaturado.tarifa_com_tributos" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-3">
                                        <label for="tarifa-sem-tributos-item-faturado">Tarifa s/ tributos</label>
                                        <InputText 
                                            id="tarifa-sem-tributos-item-faturado" 
                                            v-model.trim="itemFaturado.tarifa_sem_tributos" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="valor-item-faturado">Valor</label>
                                        <InputText 
                                            id="valor-item-faturado" 
                                            v-model.trim="itemFaturado.valor" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="aliquota-icms-item-faturado">Alíquota ICMS</label>
                                        <InputText 
                                            id="aliquota-icms-item-faturado" 
                                            v-model.trim="itemFaturado.aliquota_icms" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>

                                <div class="grid col-12">
                                    <div class="field col-3">
                                        <label for="valor-icms-item-faturado">Valor ICMS</label>
                                        <InputText 
                                            id="valor-icms-item-faturado" 
                                            v-model.trim="itemFaturado.valor_icms" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-3">
                                        <label for="valor-pis-item-faturado">Valor PIS</label>
                                        <InputText 
                                            id="valor-pis-item-faturado" 
                                            v-model.trim="itemFaturado.valor_pis" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="valor-cofins-item-faturado">Valor COFINS</label>
                                        <InputText 
                                            id="valor-cofins-item-faturado" 
                                            v-model.trim="itemFaturado.valor_cofins" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>

                                    <div class="field col-3">
                                        <label for="valor-pis-cofins-item-faturado">Valor PIS/COFINS</label>
                                        <InputText 
                                            id="valor-pis-cofins-item-faturado" 
                                            v-model.trim="itemFaturado.valor_pis_cofins" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>

                                <div class="grid col-12">
                                    <div class="field col-3">
                                        <label for="valor-base-calc-icms-item-faturado">Valor base calc. ICMS</label>
                                        <InputText 
                                            id="valor-base-calc-icms-item-faturado" 
                                            v-model.trim="itemFaturado.valor_base_calculo_icms" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                    
                                    <div class="field col-3">
                                        <label for="valor-base-calc-pis_cofins-item-faturado">Valor base calc. PIS/COFINS</label>
                                        <InputText 
                                            id="valor-base-calc-pis_cofins-item-faturado" 
                                            v-model.trim="itemFaturado.valor_base_calculo_pis_cofins" 
                                            required="true" 
                                            autofocus 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
                            @click="this.modalItemFaturado = false" />
                        <Button label="Salvar Item Faturado" icon="pi pi-check" class="p-button-success p-button-sm"
                            @click="salvarItemFaturado" />
                    </template>
                </Dialog>

            </div>
        </div>
    </div>

    <div v-if="isLoading" class="loading-bar-container">
      <div class="loading-bar"></div>
    </div>
</template>

<style>
.loading-bar-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1); /* Fundo leve para destacar o progresso */
  z-index: 9999;
}

.loading-bar {
  height: 100%;
  background-color: #53C65F; /* Cor de sucesso */
  width: 0; /* Começa vazia */
  animation: loading-animation 2.5s linear forwards; /* Animação predefinida */
}

@keyframes loading-animation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>

<script>

import { FilterMatchMode } from "primevue/api";
import funcoes from '../../funcoes.js';
import { estruturasFaturas } from "../../configs/estruturasFaturas.js";

export default {
    mixins: [funcoes],
    data() {
        return {
            tipos_erros: [
                { label: "Layout não Encontrado", value: 1 },
                { label: "Campos não Mapeados", value: 2 },
                { label: "Campos não Extraidos ", value: 3 },
                { label: "Implantação Divergente", value: 4 },
            ],
            isLoading: false,
            loadingBarColor: '',
            loading: false,
            filtros: [],
            ModalFiltros: false,
            
            selectImplantacoes: null,
        
            errosCockpit: [],
            errosCockpitFatura: [],
            tiposItensNormalizados: [],
            tiposClassesUnidades: [],
            tiposSubClassesUnidades: [],
            tiposModalidadesUnidades: [],

            fatura: {},
            modalFatura: false,

            itemFaturado: {},
            modalItemFaturado: false,

            medidor: {},
            modalEditMedidor: false,
            medidorDialog: false,

            dados_faturas: {},
            modalEditDados: false,

            dados_fiscais: {},
            modalEditDadosFiscais: false,

            dados_tributos: {},
            modalEditDadosTributos: false,

            deleteModalFatura: false,
            deleteMedidorDialog: false,
            deleteDadosDialog: false,
            deleteDadosFiscaisDialog: false,
            deleteDadosTributosDialog: false,
            deleteItensFaturadosDialog: false,
            deleteProductsDialog: false,

            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            submitted: false,

            tipos_status: [
                { label: "Processo Iniciado", value: 0 },
                { label: "Separação Iniciada", value: 1 },
                { label: "Separação Finalizada", value: 2 },
                { label: "Extração Iniciada", value: 3 },
                { label: "Extração Finalizada", value: 4 },
                { label: "Implantação Iniciada", value: 5 },
                { label: "Implantação Finalizada", value: 6 },
            ],

            tipos_empresas: [],
            desc_tipos_empresas: [],
            tipos_insumo: [],
            tipos_modalidades: [],
            tipos_concess: [],
            tipos_pagamentos: [],
            tipos_classe_tarifaria: [],
            tipos_subclasse: [],
            tipos_tensao: [],
            tipos_ligacao: [],
            status_array: [
                'Upload Iniciado',
                'Separação Iniciada ',
                'Separação Finalizada',
                'Extração Iniciada',
                'Extração Finalizada',
                'Implantação Iniciada',
                'Implantação Finalizada'
            ]
        };
    },

    created() {
        this.initFilters();
    },

    mounted() {
        this.listarErrosCockpit();
        this.listarTiposLigacao();
        this.listarItensNormalizados();
        this.listarClassesUnidades();
        this.listarSubClassesUnidades();
        this.listarModalidadesUnidades();
    },

    methods: {
        openPlatypus() {
            window.open('https://platypus.amee.com.br', '_blank');
        },



        /**
         * adicionarLinha - get the name of the field to add a new line at a table
         * @param nomeCampo - Invoice array field name
         * 
         * Do:
         *  - verify if there is a field
         *  - include a new line only with id_fatura, new_line_id, dateTimes.
         *  - push to array
         */
        adicionarLinha(nomeCampo) {
            if (!this.fatura[nomeCampo]) {
                console.error(`O campo ${nomeCampo} não existe em this.fatura`);
                return;
            }

            if (!estruturasFaturas[nomeCampo]) {
                console.error(`Não existe estrutura definida para ${nomeCampo}`);
                return;
            }
            
            let novaLinha = { ...estruturasFaturas[nomeCampo] };

            novaLinha.id = this.fatura[nomeCampo]?.length 
                ? this.fatura[nomeCampo][this.fatura[nomeCampo].length - 1].id + 1 
                : 1;
            novaLinha.id_fatura = this.fatura.id;
            novaLinha.created_at = new Date().toISOString().replace('Z', '000000Z');
            novaLinha.updated_at = new Date().toISOString().replace('Z', '000000Z');

            this.fatura[nomeCampo].push(novaLinha);
        },

        /**
         * salvarEdicao - save a field directly in datatable
         * 
         * @param event 
         */
        salvarEdicao(event) {
            const { data, field, newValue } = event;

            // Verifica se 'data' e 'field' são válidos antes de atribuir
            if (data && field && Object.prototype.hasOwnProperty.call(data, field)) {
                data[field] = newValue;
            } else {
                console.error("Erro ao salvar edição: dados ou campo inválidos", data, field);
            }
        },
        
        /**
         * deletarLinha - Delete a line in datatable.
         * 
         * @param item - item to delete
         * @param nomeCampo - Invoice array field name
         */
        deletarLinha(item, nomeCampo) {
            this.fatura[nomeCampo] = this.fatura[nomeCampo].filter(i => i.id !== item.id);
        },

        /** 
         * listarClassesUnidades - list all classes registered
         */
         listarClassesUnidades() {
            this.axios
                .post('/unidades/dados/tipos-classe-tarifaria/listar')
                .then((response) => {
                    this.tiposClassesUnidades = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },

        /** 
        * listarSubClassesUnidades - list all Sub classes registered
        */
        listarSubClassesUnidades() {
        this.axios
            .post('/unidades/dados/tipos-sub-classe/listar')
            .then((response) => {
                this.tiposSubClassesUnidades = response.data.dados;
            }).catch(() => {
                this.$toast.add({
                    severity: "error",
                    summary: "Ops!",
                    detail: 'Chamar a T.I',
                    life: 2500,
                });
        });
        },

        /** 
        * listarModalidadesUnidades - list all modalidades registered
        */
        listarModalidadesUnidades() {
            this.axios
                .post('/unidades/dados/tipos-modalidade-tarifaria/listar')
                .then((response) => {
                    this.tiposModalidadesUnidades = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },

        abrirFiltros() {
            this.ModalFiltros = true;
        },


        /** 
         * listarErrosCockpit - list all cockpit
         */
        listarErrosCockpit() {
            this.axios
                .post("/cockpit/listar")
                .then((response) => {
                    this.errosCockpit = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },


        /** 
         * listarTiposLigacao - list all tipos ligações
        */
        listarTiposLigacao() {
            this.axios
                .post('/unidades/dados/tipos-ligacao/listar')
                .then((response) => {
                    this.tipos_ligacao = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },


        /** 
         * listarItensNormalizados - list all itens normalizados
        */
        listarItensNormalizados() {
            this.axios
                .post('/dicionario/normalizados')
                .then((response) => {
                    this.tiposItensNormalizados = response.data.dados;
                }).catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
            });
        },


        abrirModalEdicaoFatura(id_fatura, erros) {
            this.axios
                .post("/faturas/listar-fatura", {'id_fatura': id_fatura})
                .then((response) => {
                    this.fatura = response.data.dados;
                    this.fatura.link_fatura = this.fatura.link_fatura.replace('.json', '');
                    
                    this.errosCockpitFatura = erros.filter(erro => erro.status === 0);

                    this.modalFatura = true;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },


        /**
         * salvarFatura - Save the updated invoice
         */
        salvarFatura() {
            this.loading = true;
            this.axios
                .post("/cockpit/validar-erros", this.fatura)
                .then((response) => {
                    this.fatura = response.data.dados.fatura
                    this.errosCockpitFatura = response.data.dados.erros

                    if (this.errosCockpitFatura.length === 0) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Tudo certo!",
                            detail: "A fatura não apresenta mais erros",
                            life: 2500,
                        });

                        this.isLoading = true;
                        this.loading = false;
                        this.modalFatura = false;
                        this.listarErrosCockpit();
                    } else {
                        this.$toast.add({
                            severity: "warn",
                            summary: "Atenção!",
                            detail: "Existem erros na fatura. Por favor, revise-os.",
                            life: 2500,
                        });

                        this.isLoading = true;
                        this.loading = false;
                     
                    }
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: 'Chamar a T.I',
                        life: 2500,
                    });
                });
        },

        abrirModalAdicaoItemFaturado() {            
            this.itemFaturado = {
                "id": null,
                "cci": null,
                "id_classificacao": null,
                "descricao": {
                    "id": null,
                    "descricao": null
                },
                "unidade_medida": null,
                "quantidade": null,
                "tarifa_com_tributos": null,
                "tarifa_sem_tributos": null,
                "valor": null,
                "aliquota_icms": null,
                "valor_icms": null,
                "valor_pis": null,
                "valor_cofins": null,
                "valor_pis_cofins": null,
                "valor_base_calculo_icms": null,
                "valor_base_calculo_pis_cofins": null,

            };

            this.modalItemFaturado = true;
        },

        abrirModalEdicaoItemFaturado(itemFaturado) {
            this.itemFaturado = { ...itemFaturado };
            this.modalItemFaturado = true;
        },

        salvarItemFaturado() {
            const descricaoNormalizada = this.tiposItensNormalizados.find(
                i => i.id === this.itemFaturado.id_classificacao
            );

            this.itemFaturado.descricao_normalizada = descricaoNormalizada;
            this.itemFaturado.descricao = descricaoNormalizada.descricao;

            if(this.itemFaturado.id) {
                const i = this.fatura.dados_itens_faturados.findIndex(i => i.id === this.itemFaturado.id);

                if(i !== -1) {
                    this.fatura.dados_itens_faturados[i] = { ...this.itemFaturado };
                }
            } else {
                const idsExistentes = this.fatura.dados_itens_faturados.map(i => i.id).filter(id => id != null);
                const novoId = idsExistentes.length > 0 ? Math.max(...idsExistentes) + 1 : 1;

                this.itemFaturado.id = novoId;
                this.fatura.dados_itens_faturados.push(this.itemFaturado);
            }

            this.itemFaturado = {};
            this.modalItemFaturado = false;
        },

        deleteItemFaturado(idItemFaturado) {
            this.fatura.dados_itens_faturados = this.fatura.dados_itens_faturados.filter(
                (i) => i.id !== idItemFaturado
            );
        },

        editDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor = { ...medidor };
        },

        editDadosFiscais(dados_fiscais) {
            this.modalEditDadosFiscais = true;
            this.dados_fiscais = { ...dados_fiscais };
        },

        editDadosTributos(dados_tributos) {
            this.modalEditDadosTributos = true;
            this.dados_tributos = { ...dados_tributos };
        },



        editDadosFatura(dados_faturas) {
            this.modalEditDados = true;
            this.dados_faturas = { ...dados_faturas };
        },

        hideDialog() {
            this.ModalFatura = false;
            this.submitted = false;
        },

        addDadosMedidor(medidor) {
            this.modalEditMedidor = true;
            this.medidor.id_fatura = medidor.id;
        },

        


        exportCSV() {
            this.$refs.dt.exportCSV();
        },


        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },

    },
};




</script>
