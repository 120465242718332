export const estruturasFaturas = {
    dados_medidor_faturas: {
        medidor: "",
        grandeza: "",
        posto_horario: "",
        constante_medidor: "",
        leitura_anterior: "",
        leitura_atual: "",
        consumo_medidor: "",
    },
    dados_tributos_faturas: {
        descricao: "",
        aliquota: "",
        base_calculo: "",
        valor: "",
    },
    dados_demanda_contratada_faturas:{
        descricao: "",
        quantidade: "",
    },
    dados_niveis_tensao_faturas:{
        descricao: "",
        quantidade: "",
    },
    dados_leituras_faturas:{
        unidade_medida:"",
        posto_horario:"",
        leitura_atual:"",
        leitura_anterior:"",
        leitura_atual_fora_ponta: "",
        leitura_anterior_fora_ponta: "",
        leitura_atual_ponta: "",
        leitura_anterior_ponta: "",
        leitura_atual_reservado: "",
        leitura_anterior_reservado: "",
        fator_multiplicador:"",
        perdas:"",
        fator_potencia:"",
        ajuste_fator_potencia:"",
        consumo_medido:"",
        consumo_faturado:"",
        consumo_contratado:"",
        demanda_medida:"",
        demanda_contratada:"",
        dmcr_medida:"",
        ufer_medida:"",
        kva_medido:"",
        reservado_consumo:"",
        energia_injetada_fora_ponta:"",
        energia_injetada_ponta:"",
        energia_injetada_reservado:"",
        demanda_fora_ponta:"",
        demanda_ponta:"",
        ufer_fora_ponta:"",
        ufer_ponta:"",
        ufer_reservado:"",
        dmcr_fora_ponta:"",
        dmcr_ponta:"",
        tarifa_com_tributos:"",
        total:"",
    },
};
